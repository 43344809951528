import { combineReducers, Reducer } from "redux";
import { PURGE } from "redux-persist";
import axios from "axios";
import { RootState as RT } from "./state";
import { authReducer, UNAUTHORIZED } from "./auth";
import { noteReducer } from "./notification";
import { Reducer as userReducer } from "./user";
import { Reducer as menuReducer } from "./menu";
import { Reducer as ScheduleReducer } from "./schedule";
import { Reducer as PrescriptionReducer } from "./prescription";
import { Reducer as PatientReducer } from "./patient";
import { Reducer as DrugReducer } from "./drug";
import { Reducer as InvReducer } from "./invitation";
import { Reducer as DocReducer } from "./documents";
import { Reducer as LocaReducer } from "./location";
import { Reducer as doctorPreferencesReducer } from "./doctorPreferences";
import { Reducer as specialtyReducer } from "./specialty";
import { Reducer as collectDocumentReducer } from "./collectDocument";
import { Reducer as appointmentTypeReducer } from "./appointmentType";
import { Reducer as socketReducer } from "./socket";
import { Reducer as realtimeReducer } from "./realtime";
import { Reducer as filesReducer } from "./files";
import { Reducer as messagingReducer } from "./messaging";
import { Reducer as doctorNoteReducer } from "./doctorNote";
import { Reducer as marketplaceReducer } from "./marketplace";
import { Reducer as billingReducer } from "./billing";
import { Reducer as localReducer } from "./local";
import { Reducer as prescriptionLayoutReducer } from "./prescriptionLayout";
import { Reducer as eventsReducer } from "./events";
import { Reducer as procedureReducer } from "./procedure";
import { Reducer as triggerActionsReducer } from "./triggerActions";
import { Reducer as mediaPostReducer } from "./mediaPost";
import { Reducer as appointmentInstructionReducer } from "./appointmentInstruction";
import { Reducer as medicalHistoryReducer } from "./medicalHistory";
import { Reducer as immediateCareReducer } from "./immediateCare";
import { Reducer as sharedResourceReducer } from "./sharedResource";
import { Reducer as doctorReviewReducer } from "./doctorReview";
import { Reducer as dashboardReducer } from "./dashboard";

export type RootState = RT;

export interface Action<T = string, P = any> {
  type: T;
  payload: P;
}

export const reducers: Reducer<RootState> = combineReducers<RootState>({
  auth: authReducer,
  notification: noteReducer,
  user: userReducer,
  menu: menuReducer,
  schedule: ScheduleReducer,
  prescription: PrescriptionReducer,
  patient: PatientReducer,
  drug: DrugReducer,
  invitation: InvReducer,
  documents: DocReducer,
  location: LocaReducer,
  doctorPreferences: doctorPreferencesReducer,
  specialty: specialtyReducer,
  collectDocument: collectDocumentReducer,
  appointmentType: appointmentTypeReducer,
  socket: socketReducer,
  files: filesReducer,
  messaging: messagingReducer,
  doctorNote: doctorNoteReducer,
  marketplace: marketplaceReducer,
  billing: billingReducer,
  local: localReducer,
  prescriptionLayout: prescriptionLayoutReducer,
  events: eventsReducer,
  procedure: procedureReducer,
  triggerActions: triggerActionsReducer,
  mediaPost: mediaPostReducer,
  appointmentInstruction: appointmentInstructionReducer,
  medicalHistory: medicalHistoryReducer,
  immediateCare: immediateCareReducer,
  realtime: realtimeReducer,
  sharedResource: sharedResourceReducer,
  doctorReview: doctorReviewReducer,
  dashboard: dashboardReducer,
});

axios.defaults.headers = {
  "Content-Type": "application/json",
};

export const rootReducer = (state: RootState, action: any) => {
  if (action.type === UNAUTHORIZED) {
    state = reducers(state, { type: PURGE });
  }

  return reducers(state, action);
};

export type Error = any;
